
    <template>
      <section class="content element-doc">
        <h2>快速上手</h2>
<p>本节将介绍如何在项目中使用 Element3。</p>
<h3>引入 Element3</h3>
<p>你可以引入整个 Element3，或是根据需要仅引入部分组件。我们先介绍如何引入完整的 Element3。</p>
<h4>完整引入</h4>
<p>在 main.js 中写入以下内容：</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">import</span> <span class="hljs-string">&#x27;element3/lib/theme-chalk/index.css&#x27;</span>
<span class="hljs-keyword">import</span> { createApp } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> Element3 <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element3&#x27;</span>
<span class="hljs-keyword">import</span> App <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;./App.vue&#x27;</span>

createApp(App).use(Element3).mount(<span class="hljs-string">&#x27;#app&#x27;</span>)
</code></pre>
<p>以上代码便完成了 Element3 的引入。需要注意的是，样式文件需要单独引入。</p>
<h4>按需引入</h4>
<p>如果你只希望引入部分组件，比如 Button ，那么需要在 main.js 中写入以下内容：</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">import</span> <span class="hljs-string">&#x27;element3/lib/theme-chalk/button.css&#x27;</span>
<span class="hljs-keyword">import</span> { createApp } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> { ElButton } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element3&#x27;</span>
<span class="hljs-keyword">import</span> App <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;./App.vue&#x27;</span>

createApp(App).use(ElButton).mount(<span class="hljs-string">&#x27;#app&#x27;</span>)
</code></pre>
<p>完整组件列表和引入方式</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">import</span> App <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;./App.vue&#x27;</span>
<span class="hljs-keyword">import</span> { createApp } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> {
  ElRow,
  ElCol,
  ElContainer,
  ElHeader,
  ElFooter,
  ElAside,
  ElMain,
  ElIcon,
  ElButton,
  ElLink,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElInput,
  ElInputNumber,
  ElSelect,
  ElOption,
  ElOptionGroup,
  ElCascader,
  ElCascaderPanel,
  ElSwitch,
  ElSlider,
  ElTimePicker,
  ElTimeSelect,
  ElDatePicker,
  ElUpload,
  ElRate,
  ElColorPicker,
  ElTransfer,
  ElForm,
  ElFormItem,
  ElTag,
  ElProgress,
  ElTree,
  ElPagination,
  ElBadge,
  ElAvatar,
  ElAlert,
  ElLoading,
  ElMenu,
  ElMenuItem,
  ElSubmenu,
  ElMenuItemGroup,
  ElTabs,
  ElTabPane,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElPageHeader,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElSteps,
  ElStep,
  ElDialog,
  ElTooltip,
  ElPopover,
  ElPopconfirm,
  ElCard,
  ElCarousel,
  ElCarouselItem,
  ElCollapse,
  ElCollapseItem,
  ElTimeline,
  ElTimelineItem,
  ElDivider,
  ElCalendar,
  ElImage,
  ElBacktop,
  ElInfiniteScroll,
  ElDrawer,
  ElScrollbar
} <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element3&#x27;</span>

createApp(App)
  .use(ElRow)
  .use(ElCol)
  .use(ElContainer)
  .use(ElHeader)
  .use(ElFooter)
  .use(ElAside)
  .use(ElMain)
  .use(ElIcon)
  .use(ElButton)
  .use(ElLink)
  .use(ElRadio)
  .use(ElRadioButton)
  .use(ElRadioGroup)
  .use(ElCheckbox)
  .use(ElCheckboxButton)
  .use(ElCheckboxGroup)
  .use(ElInput)
  .use(ElInputNumber)
  .use(ElSelect)
  .use(ElOption)
  .use(ElOptionGroup)
  .use(ElCascader)
  .use(ElCascaderPanel)
  .use(ElSwitch)
  .use(ElSlider)
  .use(ElTimePicker)
  .use(ElTimeSelect)
  .use(ElDatePicker)
  .use(ElUpload)
  .use(ElRate)
  .use(ElColorPicker)
  .use(ElTransfer)
  .use(ElForm)
  .use(ElFormItem)
  .use(ElTag)
  .use(ElProgress)
  .use(ElTree)
  .use(ElPagination)
  .use(ElBadge)
  .use(ElAvatar)
  .use(ElAlert)
  .use(ElLoading)
  .use(ElMenu)
  .use(ElMenuItem)
  .use(ElSubmenu)
  .use(ElMenuItemGroup)
  .use(ElTabs)
  .use(ElTabPane)
  .use(ElBreadcrumb)
  .use(ElBreadcrumbItem)
  .use(ElPageHeader)
  .use(ElDropdown)
  .use(ElDropdownItem)
  .use(ElDropdownMenu)
  .use(ElSteps)
  .use(ElStep)
  .use(ElDialog)
  .use(ElTooltip)
  .use(ElPopover)
  .use(ElPopconfirm)
  .use(ElCard)
  .use(ElCarousel)
  .use(ElCarouselItem)
  .use(ElCollapse)
  .use(ElCollapseItem)
  .use(ElTimeline)
  .use(ElTimelineItem)
  .use(ElDivider)
  .use(ElCalendar)
  .use(ElImage)
  .use(ElBacktop)
  .use(ElInfiniteScroll)
  .use(ElDrawer)
  .use(ElScrollbar)
</code></pre>
<h3>全局配置</h3>
<p>在引入 Element3 时，可以传入一个全局配置对象。该对象目前支持 <code>size</code> 与 <code>zIndex</code> 字段。<code>size</code> 用于改变组件的默认尺寸，<code>zIndex</code> 设置弹框的初始 z-index（默认值：2000）。按照引入 Element3 的方式，具体操作如下：</p>
<p>完整引入 Element3：</p>
<pre><code class="hljs language-js"><span class="hljs-keyword">import</span> { createApp } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> App <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;./App.vue&#x27;</span>
<span class="hljs-keyword">import</span> Element3 <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element3&#x27;</span>

createApp(App).use(Element3, { <span class="hljs-attr">size</span>: <span class="hljs-string">&#x27;small&#x27;</span>, <span class="hljs-attr">zIndex</span>: <span class="hljs-number">3000</span> }).mount(<span class="hljs-string">&#x27;#app&#x27;</span>)
</code></pre>
<p>按需引入 Element3：</p>
<p>使用 setupGlobalOptions 设置全局配置对象</p>
<pre><code class="hljs language-js"><span class="hljs-keyword">import</span> { createApp } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
<span class="hljs-keyword">import</span> App <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;./App.vue&#x27;</span>
<span class="hljs-keyword">import</span> { ElButton, setupGlobalOptions } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element3&#x27;</span>

createApp(App)
  .use(setupGlobalOptions({ <span class="hljs-attr">size</span>: <span class="hljs-string">&#x27;small&#x27;</span>, <span class="hljs-attr">zIndex</span>: <span class="hljs-number">3000</span> }))
  .use(ElButton)
  .mount(<span class="hljs-string">&#x27;#app&#x27;</span>)
</code></pre>
<p>按照以上设置，项目中所有拥有 <code>size</code> 属性的组件的默认尺寸均为 'small'，弹框的初始 z-index 为 3000。</p>
<h3>开始使用</h3>
<p>至此，一个基于 Vue 和 Element 的开发环境已经搭建完毕，现在就可以编写代码了。各个组件的使用方法请参阅它们各自的文档。</p>

      </section>
    </template>
    
  